





import { SpotRoleAggregationWidgetAdapterPropsMixin } from '@/features/core/components/spot-role-aggregation-widget-adapter/mixin';
import {
  SpotRoleAggregationCounterConfig,
  counterConfigCurrent,
  counterConfigLastYear,
  counterConfigLastMonth,
} from '@/features/core/components/spot-role-aggregation-widget-counter-control/model';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class GasMeterAggregationWidget extends Mixins(SpotRoleAggregationWidgetAdapterPropsMixin) {
  private get configs(): SpotRoleAggregationCounterConfig[] {
    return [
      counterConfigLastMonth('currentVolume', { digitColor: 'ORANGE' }),
      counterConfigLastYear('currentVolume', { digitColor: 'ORANGE' }),
      counterConfigCurrent('currentVolume', { digitColor: 'ORANGE' }),
    ];
  }
}
